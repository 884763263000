.Home {
  width: 100%;
  min-height: calc(100vh - 60px);
  /* background: url('../../images/stars.svg'), #1B1B25; */
  background: url("../../images/stars.svg"), #032e52;
  background-repeat: repeat;

  color: #ffffff;

  display: flex;
  align-items: center;
}

.Home__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* @media screen and (min-width: 768px) { */

@media screen and (min-width: 768px) {
  .Home__col {
    align-items: flex-start;
  }
}

.row-bordered {
  /* border-top: 1pt solid #feffff; */
  border-bottom: 1pt solid #feffff;
  margin: 0 0px;
}

.short-div {
  height: 25px;
}

.center {
  margin: auto;
  width: 80%;
  /* border: 3px solid #73ad21; */
  padding: 10px;
}
